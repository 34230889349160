import Vue from 'vue';
import Clipboard from 'clipboard';
export var clipboardSuccess = function clipboardSuccess() {
  return Vue.prototype.$message({
    message: 'Copy successfully',
    type: 'success',
    duration: 1500
  });
};
export var clipboardError = function clipboardError() {
  return Vue.prototype.$message({
    message: 'Copy failed',
    type: 'error'
  });
};
export var handleClipboard = function handleClipboard(_text, event) {
  var clipboard = new Clipboard(event.target, {
    text: function text() {
      return _text;
    }
  });
  clipboard.on('success', function () {
    clipboardSuccess();
    clipboard.destroy();
  });
  clipboard.on('error', function () {
    clipboardError();
    clipboard.destroy();
  });
  clipboard.onClick(event);
};