var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashboard-editor-container",
      staticStyle: { padding: "15px 15px" }
    },
    [
      _c(
        "el-card",
        { staticClass: "box-card margin-bottom-md" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("agents.createLink")))])]
          ),
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: { model: _vm.formData, rules: _vm.ruleCheck }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "type",
                    label: _vm.$t("agents.agentType"),
                    "label-width": "160px"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("agents.selectAgentType") },
                      model: {
                        value: _vm.formData.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type"
                      }
                    },
                    _vm._l(_vm.types || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: _vm.$t(item.dec),
                          value: item.value,
                          "v-if": item.show
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "link",
                    label: _vm.$t("agents.createLink"),
                    "label-width": "160px"
                  }
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.formData.link,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "link", $$v)
                        },
                        expression: "formData.link"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "copy-btn",
                          attrs: {
                            slot: "append",
                            type: "primary",
                            icon: "el-icon-document"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleClipboard(
                                _vm.formData.link,
                                $event
                              )
                            }
                          },
                          slot: "append"
                        },
                        [_c("i", { staticClass: "fa fa-clipboard" })]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-form-item", [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    staticClass: "el-button el-button--primary",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.buildLink("formData")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("agents.createAgentRegister")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v("\n          " + _vm._s(_vm.message) + "\n      ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }